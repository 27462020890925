import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import loading from "redux/reducers/loading";
import alert from "redux/reducers/alert";
import masterData from "redux/reducers/masterData";
import member from "redux/reducers/member";
import order from "redux/reducers/order";
import report from "redux/reducers/report";
import library from "redux/reducers/library";
import notification from "redux/reducers/notification";
import callcenter from "redux/reducers/callcenter";

const composeEnhancers =
  (process.env.NODE_ENV === "development" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const enhancer = composeEnhancers(applyMiddleware(thunk));

export default createStore(
  combineReducers({
    loading,
    alert,
    masterData,
    member,
    order,
    report,
    library,
    notification,
    callcenter
  }),
  enhancer
);
