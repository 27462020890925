import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { NavLink, Link } from "react-router-dom";

import imageLogo from "assets/images/logo-Engineers-09.png";

const Div = styled.div`
  display: flex;
  height: 100vh;

  > .sidebar-left {
    display: flex;
    flex-direction: column;
    min-width: 296px;
    border-right: 1px solid #e8ecef;
    background: #fff;
    color: #000;

    .header-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 70px;
      border-bottom: 1px solid #e8ecef;
      background: #fff;

      img {
        width: 235px;
        margin: 0 auto;
        height: auto;
      }
    }

    .user-wrapper {
      text-align: center;
      padding: 51px 12px;
      border-bottom: 1px solid #e8ecef;

      .image-profile {
        width: 140px;
        height: 140px;
        margin: 0 auto;
        background: #e8ecef;
        border-radius: 50%;
      }

      .email {
        margin-top: 33px;
        font-weight: bold;
      }
    }

    .main-menu {
      flex: 1;
      display: flex;
      flex-direction: column;
      /* overflow-y: auto; */

      a {
        position: relative;
        display: block;
        padding: 22px 32px;
        font-size: 18px;
        text-decoration: none;
        color: inherit;

        &.active {
          border-left: 3px solid #a81e21;
        }
      }
    }

    .sub-menu {
      padding: 19px;
      border-top: 1px solid #e8ecef;
      text-align: center;
      background-color: #a81e21;
      color: #fff;

      a {
        font-weight: bold;
        color: inherit;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
    
  }

  > .main-content {
    flex: 1;

    > .header-wrapper {
      height: 70px;
      border-bottom: 1px solid #e8ecef;
      font-family: "kelson_sansbold";
      font-size: 20px;
      padding: 22px 60px;
      background: #fff;
      color: #a81e21;
      font-weight: bold;
    }

    > .content {
      padding: 29px 60px;
      height: calc(100% - 70px);
      overflow-y: auto;
    }
  }

  .header-title {
    float: left;
  }

  .new-user-container {
    display: flex;
    align-items: center;
    float: right;
  }

  .user-image {
    border-radius: 50%;
    background-color: #0079a5;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    color: #ffffff;
  }
  
  .user-image h3 {
    margin: 0;
  }
  
  .user-info {
    margin-right: 24px;
  }
  
  .user-info .user-employee_name {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.57;
    color: #3f3f42;
  }
  .user-info .user-position {
    font-size: 12px;
    color: #3f3f42;
    font-weight: 100;
    line-height: 1.57;
  }
`;

export default ({ title, hideMenu, children }) => {
  const { email } = localStorage.getItem(localStorage.getItem("user"))
    ? JSON.parse(localStorage.getItem("user"))
    : { email: "" };

  const [fullname, setFullname] = useState("");
  const [firstChar, setFirstChar] = useState("A");

  //const userData = localStorage.getItem(localStorage.getItem("user"))? JSON.parse(localStorage.getItem("user")): {};
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user"));
    console.log("localStorage: ", JSON.parse(localStorage.getItem("user")));
    setTimeout(() => {
      let name = userData.first_name + ' ' + userData.last_name;
      setFullname(name);
      let first_name = userData.first_name;
      if (first_name) {
        setFirstChar(first_name.substring(0, 1));
      }
    }, "1000");

  }, []);
  return (
    <Div>
      <div className="sidebar-left">
        <div className="header-wrapper">
          <img src={imageLogo} />
        </div>

        <div className="main-menu">
          {!hideMenu && (
            <>
              {/* <NavLink
                className="link-library"
                activeClassName="active"
                to="/library"
              >
                คลังข้อสอบ
              </NavLink> */}
              {/* <NavLink
                className="link-library"
                activeClassName="active"
                to="/report"
              >
                Report
              </NavLink> */}

              <NavLink
                className="link-library"
                activeClassName="active"
                to="/call-center"
              >
                Call Center
              </NavLink>
              <NavLink
                className="link-notification"
                activeClassName="active"
                to="/notification"
              >
                Notification
              </NavLink>

              {/* <NavLink
                className="link-library"
                activeClassName="active"
                to="/accounting"
              >
                Accounting Management
              </NavLink> */}
            </>
          )}
        </div>
        <div className="sub-menu">
          <Link className="link-logout" to="/logout">
            Log out
          </Link>
        </div>
      </div>
      <div className="main-content">
        <div className="header-wrapper">
          <div className="header-title">{title}</div>
          <div className="new-user-container">
            <div className="user-image">
              <h3>{firstChar}</h3>
            </div>

            <div className="user-info">
              <div className="user-employee_name">
                {fullname ? fullname : "-"}
              </div>
              <div className="user-position">
                {"เจ้าหน้าที่ดูแลระบบ"}
              </div>
            </div>


          </div>
        </div>
        <div className="content">{children}</div>
      </div>
    </Div>
  );
};
